import "./App.scss";
import { Routes, Route, Navigate } from "react-router";
import { RegisterPage } from "./Shared/Pages/RegisterPage";
import { LoginPage } from "./Shared/Pages/LoginPage";
import { HomePage } from "./Shared/Pages/HomePage";
import { useAuth } from "./Shared/Hooks/AuthProvider";
import { ForgotPasswordPage } from "./Shared/Pages/ForgotPasswordPage";
import { ResetPasswordPage } from "./Shared/Pages/ResetPasswordPage";
import { MyTrainingSpots } from "./Shared/Pages/MyTrainingSpotsPage";
import { AddNewTrainingSpotPage } from "./Shared/Pages/AddNewTrainingSpotPage";
import { TrainingSpotPage } from "./Shared/Pages/TrainingSpotPage";
import { AdminArea } from "./Shared/Pages/AdminArea";
import { adminRole, roleClaim } from "./constants";
import { MyProfile } from "./Shared/Pages/MyProfile";
import { useContent } from "./Shared/Hooks/ContentProvider";
import { HelmetProvider } from "react-helmet-async";
import Footer from "./Shared/Components/Footer/Footer";
import { GlobalDataPage } from "./Shared/Pages/GlobalDataPage/GlobalDataPage";
import { CityInfoPage } from "./Shared/Pages/CityInfoPage/CityInfoPage";
import { Link } from "react-router-dom";

export default function App() {
  const { isLoggedIn, user } = useAuth();
  const { headerContent } = useContent();
  const isAdmin = user && user[roleClaim] === adminRole;

  return (
    <div className="App">
      <header>
        <h1>Sports advisor</h1>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px", flexWrap: "wrap" }}>
          <Link to={"/"}>{headerContent.home}</Link>
          {isLoggedIn && (
            <>
              <Link to={"my-training-spots"}>
                {headerContent.myTrainingSpots}
              </Link>
              <Link to={"my-profile"}>{headerContent.myProfile}</Link>
            </>
          )}
          {isAdmin && <Link to={"admin-area"}>Admin area</Link>}
          {!isLoggedIn && (
            <>
              <Link to={"login"}>{headerContent.login}</Link>
              <Link to={"register"}>{headerContent.register}</Link>
              <Link to={"forgot-password"}>{headerContent.forgotPassword}</Link>
            </>
          )}
        </div>
      </header>
      <main>
        <HelmetProvider>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="/:trainingSpotId" element={<TrainingSpotPage />} />
            <Route path="/locations" element={<GlobalDataPage />} />
            <Route
              path="/locations/:country_code/:param1?/:param2?"
              element={<CityInfoPage />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
            {isLoggedIn && (
              <>
                <Route path="my-training-spots" element={<MyTrainingSpots />} />
                <Route
                  path="add-new-training-spot"
                  element={<AddNewTrainingSpotPage />}
                />
                <Route
                  path="edit-training-spot/:trainingSpotId"
                  element={<AddNewTrainingSpotPage />}
                />
                <Route path="my-profile" element={<MyProfile />} />
                <Route path="admin-area" element={<AdminArea />} />
              </>
            )}
            {!isLoggedIn && (
              <>
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route
                  path="forgot-password"
                  element={<ForgotPasswordPage />}
                />
              </>
            )}
          </Routes>
        </HelmetProvider>
      </main>
      <Footer />
    </div>
  );
}
