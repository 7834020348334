import { MapEvent, useMap } from "@vis.gl/react-google-maps";
import { formatLocationName } from "../../Helpers/formatLocationName";
import { useContent } from "../../Hooks/ContentProvider";
import {
  GpsLocation,
  ICityInfo,
  IMapMarkers,
  ITrainingSpot,
} from "../../Interfaces";
import { TrainingSpotsMap } from "../TrainingSpotsMap/TrainingSpotsMap";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import {
  apiURL,
  defaultMapZoom,
  replacementString,
  skipTakeDefault,
} from "../../../constants";
import geohash from "ngeohash";
import { useLoading } from "../../Hooks/LoadingProvider";
import { TrainingSpot } from "../TrainingSpot/TrainingSpot";
import useErrorData from "../../Hooks/ErrorData";
import { ErrorList } from "../ErrorList/ErrorList";
import { LoadingSpinner } from "seb-components-library";
import "./CityInfo.scss";
import { Helmet } from "react-helmet-async";

interface ICityInfoProps {
  cityInfo: ICityInfo;
}
export const CityInfo = ({ cityInfo }: ICityInfoProps) => {
  const { cityInfoContent, homePageContent } = useContent();
  const [mapLoading, setMapLoading] = useState<boolean>(true);
  const { adjustLoadingCounter } = useLoading();
  const [errorData, setErrors] = useErrorData();
  const [trainingSpotsByDistance, setTrainingSpotsByDistance] = useState<
    ITrainingSpot[]
  >([]);
  const [trainingSpotsLocationData, setTrainingSpotsLocationData] = useState<
    IMapMarkers[]
  >([]);
  const [selectedMarkerKey, setSelectedMarkerKey] = useState<string | null>(
    null
  );
  const [byDistanceSkip, setByDistanceSkip] = useState<number>(0);
  const [tempMarkerKey, setTempMarkerKey] = useState<string | null>(null);
  const [currentGeoHash, setCurrentGeoHash] = useState<string>();
  const [isLoadingMoreByDistance, setIsLoadingMoreByDistance] =
    useState<boolean>(false);
  const map = useMap();

  async function fetchGlobalData(lng: number, lat: number) {
    setMapLoading(true);
    try {
      const response = await axios.get(
        `${apiURL}/api/TrainingSpots/mapData?posX=${lng}&posY=${lat}`
      );
      setTrainingSpotsLocationData(response.data);
    } catch (error: any) {
      console.error(error);
    } finally {
      setMapLoading(false);
    }
  }

  const handleMapIdle = (e: MapEvent) => {
    let lng = e.map.getCenter()?.lng();
    let lat = e.map.getCenter()?.lat();
    let tempGeoHash = lng && lat && geohash.encode(lat, lng, 3);
    if (currentGeoHash !== tempGeoHash && lat && lng && currentGeoHash) {
      fetchGlobalData(lng, lat);
    }
    tempGeoHash && setCurrentGeoHash(tempGeoHash);
  };

  function forceInfoWindowClose() {
    setTempMarkerKey(null);
    setSelectedMarkerKey(null);
  }

  const handleMarkerClick = useCallback((marker: IMapMarkers) => {
    forceInfoWindowClose();
    setSelectedMarkerKey(marker.id);
  }, []);

  const handleInfoWindowClose = useCallback(() => {
    setSelectedMarkerKey(null);
  }, []);

  async function handleShowOnMap(trainingSpotId: string) {
    forceInfoWindowClose();
    map?.setZoom(defaultMapZoom * 1.2);
    map?.getDiv()?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
    if (trainingSpotsLocationData.find((x) => x.id === trainingSpotId)) {
      setSelectedMarkerKey(trainingSpotId);
    } else {
      try {
        const response = await axios.get(
          `${apiURL}/api/TrainingSpots/${trainingSpotId}`
        );
        const trainingSpot: ITrainingSpot = response.data;
        map?.setCenter({ lng: trainingSpot.posX, lat: trainingSpot.posY });
        setTempMarkerKey(trainingSpotId);
      } catch (error: any) {
        setErrors(error?.response?.data?.errors);
      }
    }
  }

  useEffect(() => {
    if (!tempMarkerKey || !trainingSpotsLocationData) return;

    if (trainingSpotsLocationData.find((x) => x.id === tempMarkerKey)) {
      setSelectedMarkerKey(tempMarkerKey);
    }
  }, [trainingSpotsLocationData, tempMarkerKey]);

  const selectedMarker = useMemo(
    () =>
      trainingSpotsLocationData && selectedMarkerKey
        ? trainingSpotsLocationData.find((t) => t.id === selectedMarkerKey)!
        : null,
    [trainingSpotsLocationData, selectedMarkerKey]
  );

  const fetchTrainingSpotsByDistance = async (
    response: GpsLocation,
    skip: number
  ) => {
    try {
      setErrors([]);
      const trainingSpotsByDistance = await axios.get(
        `${apiURL}/api/TrainingSpots/byDistance?posX=${response.lng}&posY=${response.lat}&skip=${skip}&take=${skipTakeDefault}`
      );
      setTrainingSpotsByDistance((prev) => {
        const existingIds = new Set(prev.map((spot) => spot.id));
        const filteredNewSpots: ITrainingSpot[] =
          trainingSpotsByDistance.data.trainingSpots.filter(
            (spot: ITrainingSpot) => !existingIds.has(spot.id)
          );
        return [...prev, ...filteredNewSpots];
      });
    } catch (error: any) {
      console.error(error);
      setErrors(error);
    } finally {
      setIsLoadingMoreByDistance(false);
    }
  };

  const fetchTrainingSpotsCombined = async (
    response: GpsLocation,
    skip: number = 0
  ) => {
    try {
      setErrors([]);
      const { lng, lat } = response;

      if (lng && lat) {
        // Set the map center
        map?.setCenter({ lng, lat });

        // Fetch the training spots
        const combinedResponse = await axios.get(
          `${apiURL}/api/TrainingSpots/TrainingSpotsCombined?posX=${lng}&posY=${lat}&skip=${skip}&take=${skipTakeDefault}`
        );
        const { byDistance, trainingSpots } = combinedResponse.data;

        // Update the state with the new spots
        setTrainingSpotsByDistance((prev) => {
          const existingIds = new Set(prev.map((spot) => spot.id));
          const filteredNewSpots = byDistance.filter(
            (spot: ITrainingSpot) => !existingIds.has(spot.id)
          );
          return [...prev, ...filteredNewSpots];
        });

        // Set the training spots data
        setTrainingSpotsLocationData(trainingSpots);

        // Set geohash
        const tempGeoHash = geohash.encode(lat, lng, 3);
        tempGeoHash && setCurrentGeoHash(tempGeoHash);
      }
    } catch (error: any) {
      setErrors(error);
    } finally {
      adjustLoadingCounter(-1);
      setMapLoading(false);
    }
  };

  useLayoutEffect(() => {
    const { longitude, latitude, name } = cityInfo;
    fetchTrainingSpotsCombined({
      lng: longitude,
      lat: latitude,
      city: name[cityInfoContent.language].city,
      isAccurate: false,
    });
    // eslint-disable-next-line
  }, [adjustLoadingCounter]);

  return (
    <div className="cityInfoContainer">
      <Helmet>
        {cityInfo.name && (
          <>
            <title>
              {cityInfoContent.pageTitle.replaceAll(
                replacementString,
                formatLocationName(cityInfo.name[cityInfoContent.language])
              )}
            </title>
            <meta
              name="description"
              content={cityInfoContent.pageDescription.replaceAll(
                replacementString,
                formatLocationName(cityInfo.name[cityInfoContent.language])
              )}
            />
            <meta
              name="keywords"
              content={cityInfoContent.pageKeywords.replaceAll(
                replacementString,
                formatLocationName(cityInfo.name[cityInfoContent.language], " ")
              )}
            />
          </>
        )}
      </Helmet>
      <h2>
        Training areas in{" "}
        {formatLocationName(cityInfo.name[cityInfoContent.language])}
      </h2>
      <ErrorList errors={errorData} />
      <TrainingSpotsMap
        lat={cityInfo.latitude}
        lng={cityInfo.longitude}
        trainingSpotsLocationData={trainingSpotsLocationData}
        selectedMarker={selectedMarker}
        mapLoading={mapLoading}
        handleInfoWindowClose={handleInfoWindowClose}
        onIdle={(e: MapEvent) => handleMapIdle(e)}
        handleMarkerClick={handleMarkerClick}
        isAccurate={false}
      />
      {trainingSpotsByDistance.map((trainingSpot) => {
        return (
          <TrainingSpot
            key={trainingSpot.id}
            trainingSpot={trainingSpot}
            handleShowOnMap={handleShowOnMap}
            ratingLabel={homePageContent.ratingLabel}
            viewLabel={homePageContent.viewLabel}
            showOnMapLabel={homePageContent.showOnMapLabel}
          />
        );
      })}
      {!isLoadingMoreByDistance ? (
        trainingSpotsByDistance.length > 0 && (
          <button
            onClick={() => {
              setIsLoadingMoreByDistance(true);
              fetchTrainingSpotsByDistance(
                {
                  lat: cityInfo.latitude,
                  lng: cityInfo.longitude,
                  city: cityInfo.name[cityInfoContent.language].city,
                  isAccurate: false,
                },
                byDistanceSkip + skipTakeDefault
              );
              setByDistanceSkip((prev) => prev + skipTakeDefault);
            }}
          >
            {homePageContent.loadMore}
          </button>
        )
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};
