import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
      <div style={{ display: "flex", gap: "1rem" }}>
        <p>
          © {new Date().getFullYear()} Sports Advisor. All rights reserved.
        </p>
        <Link to={"/locations"}>Show Global Data</Link>
      </div>
    </footer>
  );
};

export default Footer;
