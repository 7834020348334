import axios from "axios";
import { GpsLocation } from "../Interfaces";
import { formatLocationName } from "./formatLocationName";

export const getCityName = async (lat: number, lon: number, language: string): Promise<string | undefined> => {
    try {
        const response = await axios.get(`https://geo-location-lookup.seb-server.com/city-info-by-coordinates?latitude=${lat}&longitude=${lon}&language=${language}`);
        const locationData = response.data.name[language];
        return formatLocationName(locationData);
    } catch (error) {
        console.error(error);
        return undefined;
    }
};

const fetchLocationData = async (url: string, language: string): Promise<GpsLocation | null> => {
    const response = await axios.get(url).catch((error) => {
        console.error(`Error fetching location from ${url}:`, error);
        return null;
    });

    if (response && response.status === 200 && response.data.latitude && response.data.longitude) {
        const cityName = await getCityName(response.data.latitude, response.data.longitude, language).catch((error) => {
            console.error(`Error fetching city name:`, error);
            return null;
        });

        return {
            lng: response.data.longitude,
            lat: response.data.latitude,
            city: cityName ?? undefined,
            isAccurate: false,
        };
    }

    console.warn(`Provider at ${url} returned invalid data or failed`);
    return null;
};

export const fetchIpLocationNew = async (language: string): Promise<GpsLocation> => {
    const providers = [
        'https://ipwhois.app/json/',
        'https://geolocation-db.com/json/',
        'https://ipapi.co/json/'
    ];

    for (const provider of providers) {
        const location = await fetchLocationData(provider, language);
        if (location) {
            return location;
        }
    }

    // If all providers fail
    return {
        lng: undefined,
        lat: undefined,
        city: undefined,
        isAccurate: false,
    };
};

export const fetchGpsLocation = (language: string): Promise<GpsLocation> => {
    return new Promise((resolve, reject) => {
        const successCallback = async (position: { coords: { latitude: any; longitude: any; }; }) => {
            const cityName = await getCityName(position.coords.latitude, position.coords.longitude, language);
            const locationData = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                city: cityName,
                isAccurate: true,
            };
            resolve(locationData);
        };

        const errorCallback = (error: any) => {
            console.error(error);
            reject({ lat: undefined, lng: undefined, city: undefined });
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    });
};