import { useAuth } from "../Hooks/AuthProvider";
import { GpsLocation, IMapMarkers, ITrainingSpot, User } from "../Interfaces";
import { apiURL, defaultMapZoom, minimumLoadingDuration, replacementString, skipTakeDefault } from "../../constants";
import { TrainingSpot } from "../Components/TrainingSpot/TrainingSpot";
import { ErrorList } from "../Components/ErrorList/ErrorList";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import axios from "axios";
import useErrorData from "../Hooks/ErrorData";
import { useLoading } from "../Hooks/LoadingProvider";
import { fetchGpsLocation, fetchIpLocationNew } from "../Helpers/GetLocation";
import { MapEvent, useMap } from '@vis.gl/react-google-maps';
import geohash from 'ngeohash';
import { LoadingSpinner } from "seb-components-library";
import { ProfilePicture } from "../Components/ProfilePicture/ProfilePicture";
import { AutocompleteCustomHybrid } from "../Components/Autocomplete";
import { useContent } from "../Hooks/ContentProvider";
import { Helmet } from "react-helmet-async";
import { TrainingSpotsMap } from "../Components/TrainingSpotsMap/TrainingSpotsMap";
import { formatLocationName } from "../Helpers/formatLocationName";

export const HomePage = () => {
    // TODO: Refactor state management, too many state values
    const { user, logout, isLoggedIn } = useAuth();
    const [gpsLocation, setGpsLocation] = useState<GpsLocation>()
    const [trainingSpotsLocationData, setTrainingSpotsLocationData] = useState<IMapMarkers[]>([]);

    // TODO: Will need to be refactored to store it in a state management system
    const [trainingSpotsByDistance, setTrainingSpotsByDistance] = useState<ITrainingSpot[]>([]);
    const [isLoadingMoreByDistance, setIsLoadingMoreByDistance] = useState<boolean>(false)
    const [byDistanceSkip, setByDistanceSkip] = useState<number>(0)
    const [userData, setUserData] = useState<User>()
    const [userDisplayNameLoading, setUserDisplayNameLoading] = useState<boolean>(false)
    // Until here
    const { homePageContent } = useContent();
    const [mapLoading, setMapLoading] = useState<boolean>(false)
    const [currentGeoHash, setCurrentGeoHash] = useState<string>()
    const [errorData, setErrors] = useErrorData();
    const { adjustLoadingCounter } = useLoading();
    const [selectedMarkerKey, setSelectedMarkerKey] = useState<string | null>(null);
    const [tempMarkerKey, setTempMarkerKey] = useState<string | null>(null);
    const map = useMap();

    const selectedMarker = useMemo(
      () =>
        trainingSpotsLocationData && selectedMarkerKey
          ? trainingSpotsLocationData.find((t) => t.id === selectedMarkerKey)!
          : null,
      [trainingSpotsLocationData, selectedMarkerKey]
    );
    const handleMapIdle = (
        e: MapEvent,
      ) => {
        let lng = e.map.getCenter()?.lng();
        let lat = e.map.getCenter()?.lat();
        let tempGeoHash = lng && lat && geohash.encode(lat, lng, 3);
        if (currentGeoHash !== tempGeoHash && lat && lng && currentGeoHash) {
          fetchGlobalData(lng, lat);
        }
        tempGeoHash && setCurrentGeoHash(tempGeoHash);
      };

    function forceInfoWindowClose() {
        setTempMarkerKey(null);
        setSelectedMarkerKey(null)
    }

    const handleInfoWindowClose = useCallback(() => {
        setSelectedMarkerKey(null);
    }, []);

    const handleMarkerClick = useCallback((marker: IMapMarkers) => {
        forceInfoWindowClose()
        setSelectedMarkerKey(marker.id);
    }, []);

    const fetchTrainingSpotsCombined = async (response: GpsLocation, skip: number) => {
        try {
            setErrors([]);
            const combinedResponse = await axios.get(`${apiURL}/api/TrainingSpots/TrainingSpotsCombined?posX=${response.lng}&posY=${response.lat}&skip=${skip}&take=${skipTakeDefault}`);

            const { byDistance, trainingSpots } = combinedResponse.data;
        
            setTrainingSpotsByDistance((prev) => {
                const existingIds = new Set(prev.map((spot) => spot.id));
                const filteredNewSpots: ITrainingSpot[] = byDistance.filter((spot: ITrainingSpot) => !existingIds.has(spot.id));
                return [...prev, ...filteredNewSpots];
            });
    
            setTrainingSpotsLocationData(trainingSpots);
        } catch (error: any) {
            console.error(error);
            setErrors(error);
        } finally {
            adjustLoadingCounter(-1);
        }
    };
    

    async function fetchGlobalData(lng: number, lat: number) {
        setMapLoading(true)
        try {
            const response = await axios.get(`${apiURL}/api/TrainingSpots/mapData?posX=${lng}&posY=${lat}`);
            setTrainingSpotsLocationData(response.data);
            setTimeout(
                () => {
                    setMapLoading(false)
                }, 0
            )
        } catch (error: any) {
            console.error(error);
        }
    }

    const fetchTrainingSpotsByDistance = async (response: GpsLocation, skip: number) => {
        try {
            setErrors([])
            const trainingSpotsByDistance = await axios.get(`${apiURL}/api/TrainingSpots/byDistance?posX=${response.lng}&posY=${response.lat}&skip=${skip}&take=${skipTakeDefault}`);
            setTrainingSpotsByDistance(((prev) => {
                const existingIds = new Set(prev.map((spot) => spot.id));
                const filteredNewSpots: ITrainingSpot[] = trainingSpotsByDistance.data.trainingSpots.filter((spot: ITrainingSpot) => !existingIds.has(spot.id));
                return [...prev, ...filteredNewSpots];
            }));
        } catch (error: any) {
            console.error(error);
            setErrors(error);
        } finally {
            setIsLoadingMoreByDistance(false)
        }
    }


    const fetchUserDisplayName = async (userId: string) => {
        setUserDisplayNameLoading(true)
        try {
            const response = await axios.get(`${apiURL}/api/Account/user-data/${userId}`)
            setUserData(response.data)
        } catch (error: any) {
            console.error(error);
            setUserData({ name: 'user', profilePictureFileName: null })
            setErrors(error.data);
        } finally {
            setUserDisplayNameLoading(false)
        }
    }


// Simplify fetchTrainingSpots to make it cleaner
const fetchTrainingSpots = async (response: GpsLocation) => {
    if (response.lng && response.lat) {
        map?.setCenter({ lng: response.lng, lat: response.lat });
        const geoHash = geohash.encode(response.lat, response.lng, 3);
        setCurrentGeoHash(geoHash);
    }

    await fetchTrainingSpotsCombined(response, 0).catch((error) => {
        console.error('Error fetching training spots:', error);
        setErrors(error);
    });
};


const fetchTrainingSpotsByLocation = async (language: string) => {
    const location = await fetchIpLocationNew(language).catch((error) => {
        console.error('Error in fetchIpLocationNew:', error);
        return { lng: undefined, lat: undefined, city: undefined };
    });

    if (!location.lng || !location.lat) {
        setErrors([homePageContent.ipLocationFailMessage]);
        adjustLoadingCounter(-1);
    } else {
        setGpsLocation(location);
        await fetchTrainingSpots(location);
    }
};

    const handleGetLocalTrainingSpotsClick = async (language: string) => {
      const startTime = Date.now();
      adjustLoadingCounter(1);
      setSelectedMarkerKey(null);
      await fetchGpsLocation(language)
        .then((res) => {
          setGpsLocation(res);
          res.lng && res.lat && map?.setCenter({ lng: res.lng, lat: res.lat });
          return fetchTrainingSpots(res);
        })
        .catch(async () => {
          setErrors([homePageContent.gpsLocationFailMessage]);
        })
        .finally(() => {
          const elapsedTime = Date.now() - startTime;
          const remainingTime = Math.max(
            0,
            minimumLoadingDuration - elapsedTime
          );
          setTimeout(() => {
            adjustLoadingCounter(-1);
            map?.setZoom(defaultMapZoom);
          }, remainingTime);
        });
    };

    useLayoutEffect(() => {
        adjustLoadingCounter(1)
        fetchTrainingSpotsByLocation(homePageContent.language);
        // eslint-disable-next-line
    }, [adjustLoadingCounter]);

    useEffect(
        () => {
            isLoggedIn && fetchUserDisplayName(user.sub)
            // eslint-disable-next-line
        }, [isLoggedIn]
    )

    useEffect(
        () => {
            if (!tempMarkerKey || !trainingSpotsLocationData)
                return

            if (trainingSpotsLocationData.find(x => x.id === tempMarkerKey)) {
                setSelectedMarkerKey(tempMarkerKey)
            }
        }, [trainingSpotsLocationData, tempMarkerKey]
    )

    async function handleShowOnMap(trainingSpotId: string) {
        forceInfoWindowClose();
        map?.setZoom(defaultMapZoom * 1.2)
        map?.getDiv()?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
        if (trainingSpotsLocationData.find(x => x.id === trainingSpotId)) {
            setSelectedMarkerKey(trainingSpotId);
        } else {
            try {
                const response = await axios.get(`${apiURL}/api/TrainingSpots/${trainingSpotId}`);
                const trainingSpot: ITrainingSpot = response.data;
                map?.setCenter({ lng: trainingSpot.posX, lat: trainingSpot.posY })
                setTempMarkerKey(trainingSpotId);
            } catch (error: any) {
                setErrors(error?.response?.data?.errors)
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '5px' }}>
        <Helmet>
            <title>{homePageContent.pageTitle}</title>
            <meta name="description" content={homePageContent.pageDescription} />
            <meta name="keywords" content={homePageContent.pageKeywords}/>
        </Helmet>
            {isLoggedIn && userData ? (
                userDisplayNameLoading ? (
                    null
                ) : (
                    <div style={{ display: 'flex', gap: '0.25rem', alignItems: 'center' }}>
                        <p>{homePageContent.headerLoggedIn.replace(replacementString, userData.name)}</p>
                        <ProfilePicture pictureId={userData?.profilePictureFileName} />
                    </div>
                )
            ) : (
                <p>{homePageContent.header}</p>
            )}

        <AutocompleteCustomHybrid
            placeholder={homePageContent.placeholder}
            language={homePageContent.language}
            emptyResultMessage={homePageContent.emptyResultMessage}
            onFocus={() => {
                setTempMarkerKey(null);
                setSelectedMarkerKey(null);
            }}
            onPlaceSelect={(selectedItem) => {
                adjustLoadingCounter(1);
                const {  name } = selectedItem;
                const { city } = name[homePageContent.language];
                const center = { lat: selectedItem.latitude, lng: selectedItem.longitude,};

                setTrainingSpotsByDistance([]);
                map?.setCenter({lat: center.lat, lng: center.lng});
                map?.setZoom(defaultMapZoom);

                fetchTrainingSpots({lat: center.lat, lng: center.lng, city, isAccurate: false});
                setGpsLocation({ lat: center.lat, lng: center.lng, city: formatLocationName(name[homePageContent.language]), isAccurate: false });
            }}
        />

            <ErrorList errors={errorData} />
            {isLoggedIn && <button onClick={() => { logout() }}>{homePageContent.logOut}</button>}
            {gpsLocation?.lat && gpsLocation?.lng &&
                <TrainingSpotsMap 
                    lat={gpsLocation?.lat}
                    lng={gpsLocation?.lng}
                    onIdle={(e: MapEvent) => handleMapIdle(e)}
                    trainingSpotsLocationData={trainingSpotsLocationData}
                    selectedMarker={selectedMarker}
                    handleMarkerClick={handleMarkerClick}
                    handleInfoWindowClose={handleInfoWindowClose}
                    mapLoading={mapLoading}
                    isAccurate={gpsLocation.isAccurate}
                />
            }

            <button onClick={() => {handleGetLocalTrainingSpotsClick(homePageContent.language)}}>{homePageContent.getAccurateLocation}</button>
            <h2>{homePageContent.trainingSpotsIn}{gpsLocation?.city && homePageContent.trainingSpotsInSep} {gpsLocation?.city ?? homePageContent.defaultLocationName}</h2>
            {trainingSpotsByDistance.length > 0 ? <h3>{homePageContent.nearest}</h3> : <h3>{homePageContent.noTrainingSpotsNearby}</h3>}
            {
                trainingSpotsByDistance.map(trainingSpot => {
                    return (
                      <TrainingSpot
                        key={trainingSpot.id}
                        trainingSpot={trainingSpot}
                        handleShowOnMap={handleShowOnMap}
                        ratingLabel={homePageContent.ratingLabel}
                        distanceFromYoulabel={homePageContent.distanceFromYoulabel}
                        viewLabel={homePageContent.viewLabel}
                        showOnMapLabel={homePageContent.showOnMapLabel}
                      />
                    );
                })
            }
            {
                !isLoadingMoreByDistance ?
                    trainingSpotsByDistance.length > 0 && <button onClick={() => {
                        setIsLoadingMoreByDistance(true)
                        gpsLocation && fetchTrainingSpotsByDistance(gpsLocation, byDistanceSkip + skipTakeDefault)
                        setByDistanceSkip((prev => prev + skipTakeDefault))
                    }}>{homePageContent.loadMore}</button> : <LoadingSpinner />
            }
        </div>
    )
}
