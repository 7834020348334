import { defaultLocationName } from "../../constants";

export const initialLoginFormData = {
    email: '',
    password: ''
}

export const initialTrainingSpotDta = {
    posX: 0,
    posY: 0,
    title: '',
    trainingSpotPictureIds: [],
    tagIds: [],
    comment: '',
    rating: 0,

}

export const initialReviewFormData = {
    rating: 0,
    comment: '',
    trainingSpotId: '',
    id: '',
    userId: '',
    dateWritten: '',
    user: {
        name: '',
        profilePictureFileName: null
    }
}

export const initialGpsData = {
    lat: 0,
    lng: 0,
    city: defaultLocationName,
    isAccurate: false
}

export const mapContainerStyle: { [key: string]: string } = {
    position: 'relative',
    width: '90%',
    height: '500px',
}