import React from 'react';

const UserLocationIndicator: React.FC = () => {
  return (
    <div style={{
      width: '1rem',
      height: '1rem',
      backgroundColor: 'blue',
      borderRadius: '50%',
      border: '3px solid white',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.6)'
    }} />
  );
};

export default UserLocationIndicator;