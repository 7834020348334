export function getLanguageFromDomain(domain: string) {
    switch (domain) {
        case 'localhost:3000':
            return 'en';
        case 'sports-advisor.pl':
            return 'pl';
        case 'www.sports-advisor.pl':
            return 'pl';
        case 'www.sports-advisor.hu':
            return 'hu';
        case 'sports-advisor.hu':
            return 'hu';
        case 'localhost:3500':
            return 'hu';
        case 'www.sports-advisor.lt':
            return 'lt';
        case 'sports-advisor.lt':
            return 'lt';
        case 'wonderful-sea-01e202a10.5.azurestaticapps.net':
            return 'pl';
        case 'localhost:4000':
            return 'pl';
        case 'sports-advisor-staging-en.seb-server.com':
            return 'en'
        case 'sports-advisor-staging-pl.seb-server.com':
            return 'pl'
        case 'sports-advisor-staging-lt.seb-server.com':
            return 'lt'
        case 'sports-advisor-staging-hu.seb-server.com':
            return 'hu'
        default:
            return 'en';
    }
}